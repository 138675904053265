import React from "react";
import "./styles.css";

const MetroVpnPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Metro VPN Terms of Service and Privacy Policy</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Terms of Service document (the “Terms”) outlines the terms and
          conditions of use of the VPN services (the “Services” or “Service”)
          provided by Metro VPN International Ltd. (“MetroVPN”). These Terms
          also govern the use of and access to Metro VPN’s content (the
          “Content”), which includes the Metro VPN website (the “Site”),
          applications (the “Apps”), and any software provided by Metro VPN (the
          “Software”).
        </p>
        <p>
          By agreeing to these Terms, you are also agreeing to the Privacy
          Policy (“Privacy Policy”).
        </p>
      </section>
      <section className="policy-section">
        <h2>Information collected when you use our services</h2>

        <p>
          In order to continuously improve the levels of service we provide; we
          collect information about your use of our services to monitor for
          problems and look for opportunities to make improvements.
        </p>
      </section>
      <section className="policy-section">
        <h2>Usage information.</h2>

        <p>
          MetroVPN is committed to your privacy and does not collect or log
          browsing history, traffic destination, data content, or DNS queries
          from Subscribers connected to our VPN. We may collect some sensitive
          personal information, such as your email address information. We only
          collect information that are necessary for the proper delivery of the
          Site and Services.
        </p>
      </section>

      <section className="policy-section">
        <h2>Device information</h2>
        <p>
          We collect information from and about the device you use to access our
          services, including about the browsers and Metro VPN apps you use to
          access our services. For example, we may collect device identifiers,
          browser types, device types and settings, operating system versions,
          mobile, wireless, and other network information (such as internet
          service provider name, carrier name and signal strength), and
          application version numbers.
        </p>
      </section>

      <section className="policy-section">
        <h2>Diagnostic information</h2>
        <p>
          We may collect information about the nature of the requests that you
          make to our servers (such as what is being requested, information
          about the device and app used to make the request, timestamps, and
          referring URLs). However, our VPN product do not log any information
          that associates your identity with your VPN browsing activity. We do
          not maintain any records that show what you were browsing or accessing
          through a VPN connection. VPN Product Privacy Notice for more
          information.
        </p>
      </section>

      <section className="policy-section">
        <h2>Location information</h2>
        <p>
          Unless otherwise expressly stated, we do not collect your location
          information based on your device’s GPS or other device sensor data.
          However, we may collect your approximate location by calculating an
          imprecise latitude and longitude based on your IP address to provide
          you with better service (e.g., to connect you to the nearest and
          fastest VPN server).
        </p>
      </section>

      <section className="policy-section">
        <h2>How Do We Use Your Personal Information?</h2>
        <p>
          Metro VPN uses your information for the purposes described below.
          Metro VPN employs internal risk management functions to ensure we
          continue to only use your information for the purposes we disclose to
          you and are taking appropriate steps to protect that data from
          exposure.
        </p>
      </section>

      <section className="policy-section">
        <h2>To provide, maintain, troubleshoot, and support our services</h2>
        <p>
          We use your personal data for this purpose on the basis that it is
          required to fulfil our contractual obligations to you. Examples: using
          information about how much bandwidth you use and how long you use our
          services in order to provide the services in accordance with a plan to
          which you have subscribed; using threat and device information to
          determine whether certain items pose a potential security threat; and
          using usage information to troubleshoot a problem you report with our
          services and to ensure the proper functioning of our services.
        </p>
      </section>

      <section className="policy-section">
        <h2>To communicate with users and prospective users</h2>
        <p>
          We use your information to communicate with you, including by
          responding to your requests, and sending you information and updates
          about our services. We may do this in order to fulfil our contract
          with you, because you consented to the communication, or because we
          have a legitimate interest in providing you with information about our
          services.
        </p>
      </section>

      <section className="policy-section">
        <h2>To improve our services</h2>
        <p>
          We want to offer you the best services and user experiences we can, so
          we have a legitimate interest in continually improving and optimizing
          our services. To do so, we use your information to understand how
          users interact with our services. Examples: we analyze certain usage,
          device, and diagnostic information to understand aggregated usage
          trends and user engagement with our services (and, for example, invest
          in technical infrastructure to better serve regions with increasing
          user demand); we may use device and threat information to conduct
          spam, threat, and other scientific research to improve our threat
          detection capabilities; we review customer feedback to understand what
          we could be doing better.
        </p>
      </section>

      <section className="policy-section">
        <h2>To develop new services</h2>
        <p>
          We have a legitimate interest in using your information to plan for
          and develop new services. For example, we may use customer feedback to
          understand what new services users may want.
        </p>
      </section>

      <section className="policy-section">
        <h2>To market and advertise our services</h2>
        <p>
          We may use your information to provide, measure, personalize, and
          enhance our advertising and marketing based on our legitimate interest
          in offering you services that may be of interest. Examples: we may use
          information such as who or what referred you to our services to
          understand how effective our advertising is; we may use information to
          administer promotional activities such as sweepstakes and referral
          programs. Note that our VPN product do not use your VPN browsing
          activity for these purposes and we do not maintain any records that
          show what you were browsing or accessing through a VPN connection.
        </p>
      </section>

      <section className="policy-section">
        <h2>To prevent harm or liability</h2>
        <p>
          We may use information for security purposes (such as to investigate
          security issues or to monitor and prevent fraud) and to prevent abuse.
          We may do this to comply with our legal obligations, to protect an
          individual’s vital interests, or because we have a legitimate interest
          in preventing harm or liability to Metro VPN and our users. For
          example, we may use account, usage, and device information to
          determine if an entity is engaging in abusive or unauthorized activity
          in connection with our services.
        </p>
      </section>

      <section className="policy-section">
        <h2>For legal compliance.</h2>
        <p>
          We internally use your information as required by applicable law,
          legal process, or regulation. To learn about our practices regarding
          sharing your information with third parties for legal compliance
          purposes, see Section 3.1 below. We also use your information to
          enforce our legal rights and resolve disputes.
        </p>
      </section>

      <section className="policy-section">
        <h2>Tracking Technologies & Cookies</h2>
        <h2>About Tracking Technologies</h2>
        <p>
          Metro VPN uses various technologies in our services to help us collect
          information, primarily on our websites and in our marketing emails.
          For convenience, we refer to these as “tracking technologies,”
          although they are not always used to track individuals.
        </p>
      </section>

      <section className="policy-section">
        <h2>Tracking technologies include:</h2>
        <p>
          Cookies: Cookies are small portions of text that are stored on the
          device you use to access our services. Cookies enable us (or third
          parties that we allow to set cookies on your device) to recognize
          repeat users. Cookies may expire after a period of time, depending on
          what they are used for. Please see our Cookie Policy for more details.
        </p>
        <p>
          Pixel Tags / Page Tags / Web Beacons / Tracking Links: These are
          small, hidden images and blocks of code placed in web pages, ads, and
          our emails that allow us to determine if you perform a specific
          action. When you access a page, ad, or email, or click a link, these
          items let us know that you have accessed that page, opened an email,
          or clicked a link.
        </p>
        <p>
          SDKs: SDKs or software development kits are software code provided by
          our business partners that let our software interact with the services
          those partners provide. For example, in our free mobile apps, we may
          use an SDK to enable our app to serve ads from an advertising network.
          Sometimes these interactions will involve that business partner
          collecting some information from the device on which the software is
          run.
        </p>
        <p>
          Google Analytics: We use Google Analytics to help us understand how
          users use our services. Google makes available a Google Analytics Opt
          Out Browser Add-On if you do not want to participate in Google
          Analytics.
        </p>
      </section>
      <section className="policy-section">
        <h2>Processing of your data</h2>
        <p>
          Metro VPN processes user data to a limited scope – for provision of
          the Services, processing payments for the Services, and that which
          pertains to the basic functionality of the mobile applications. We
          process the following basic information:
        </p>
      </section>
      <section className="policy-section">
        <h2>Payment related information</h2>
        <p>
          Payment data (if using paid Services). This information is necessary
          to collect payments for our Services. Payment is handled by In-app
          purchase payment mechanisms of the respective Play Stores namely
          Google and Apple. We do not process this payment it is exclusively
          processed by the respective Play store.
        </p>
      </section>
      <section className="policy-section">
        <h2>Technical information</h2>
        <p>
          Statistical server load information. We monitor server performance
          (CPU, RAM, Server’s net usage) to recommend the most suitable servers
          to our customers.
        </p>
        <p>
          Username and a timestamp of the last session status. This information
          is used to limit the amount of concurrent active user sessions and is
          automatically deleted within 15 minutes after a session is terminated.
        </p>
        <p>
          Connectivity information. To prevent abuse and to be able to dispute
          unfair chargebacks, we register whether the user has used the Service
          in the last 30 days. No personally identifiable information is
          collected in this case, apart from the fact that the Service was or
          was not used during the mentioned period.
        </p>
      </section>

      <section className="policy-section">
        <h2>Information collected on our applications</h2>
        <p>
          Application diagnostics. This aggregated and anonymized data helps us
          to identify problems related to our app performance and updates. The
          collected information includes crash error reports. This information
          is general and not specific in any manner and reported by Google
          Firebase.
        </p>
        <p>
          Device information. This is reported by Google Play console and is not
          specific to any user.
        </p>

        <p>
          Device identifiers. In some cases, we may record your mobile device’s
          identifier for analytics purposes. These identifiers are assigned to
          your device by the OS manufacturer and can be reset at any time from
          your device’s settings. This information is ascertained by the Google
          services.
        </p>
      </section>
      <section className="policy-section">
        <h2>Sharing your personal data</h2>
        <p>
          We do not share your personal information with third parties except as
          described in this Privacy Policy.
        </p>
      </section>
      <section className="policy-section">
        <h2>Service providers.</h2>
        <p>
          We use third-party service providers to help us with various
          operations, such as payment processing, email automation, website and
          app diagnostics, analytics and other. As a result, some of these
          providers may process personal information.
        </p>
      </section>
      <section className="policy-section">
        <h2>Some of our main long-term service providers:</h2>
        <p>
          Application analytics and diagnostics – Google Analytics, Firebase
          Analytics (provided by Google);
        </p>
        <p>
          Sometimes our service providers, for example, distributors, resellers,
          and app store partners, will be independent controllers of your data.
          Their terms and conditions and privacy policies will apply to such
          relationships.
        </p>
        <p>
          Requests for data. Any request for user data should follow an
          appropriate official legal process to be recognized by the laws of the
          United Kingdom (e.g., mutual legal assistance treaty, letters
          rogatory). We carefully review each request to make sure it satisfies
          laws applicable to our company, laws of requesting country,
          international norms and our internal policies. Being based in United
          Kingdom allows us to keep no logs of users’ online activity (no IP
          addresses, browsing data, traffic information, etc.). This means that
          we are unable to link an individual user to a specific IP address,
          connection timestamp or other information that we do not collect.
          Therefore, even in cases we receive a rightfully served request, it
          might be impossible for us to identify a specific person or provide
          any identifying information related to that person. In cases where,
          following an appropriate legal process, we are able to identify a
          specific person, we will provide the limited data we process as per
          our Privacy Policy. Contact information for government authorities:
          Digital Service & Communications Ltd, Kemp House, 160 City Road,
          London, UK EC1V 2NX Email: lightspeedvpn44@gmail.com
        </p>
      </section>
      <section className="policy-section">
        <h2>Data security</h2>
        <p>
          We take data security very seriously and take all steps reasonably
          necessary to secure your data (whether technical, physical, or
          administrative). However, no company can guarantee the absolute
          security of internet communications. By using the Services, you
          expressly acknowledge that we cannot guarantee the security of any
          data provided to or received by us through the Services and that any
          information received from you through the website or our Services is
          provided at your own responsibility.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information for users in the European Economic Area (EEA)</h2>
        <p>
          The European Union’s General Data Protection Regulation (“GDPR”)
          requires that we provide notice in a specific way to our European
          users about their privacy rights.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information for users in California</h2>
        <p>
          If you are a California resident, you can implement your rights as
          provided in the California Consumer Privacy Act (CCPA) by contacting
          us at lightspeedvpn44@gmail.com. As per definitions in the CCPA,
          please note that Metro VPN does not sell your personal information.
        </p>
        <p>
          Our App uses UXCam which is an analytics solutions to make the app
          performance better and to provide better user experience. UXCam acts
          as a data processor for the information collected through the SDK and
          UXCam App.
        </p>
        <p>
          The process doesn’t save or share your private data by any means.
          Incase you don’t feel comfortable you can opt out at anytime. For more
          you can read it on UXCam privacy page.
        </p>
      </section>

      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at lightspeedvpn44@gmail.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: November 1, 2024</p>
      </footer>
    </div>
  );
};

export default MetroVpnPolicyPage;
